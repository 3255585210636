import styles from './Sidebar.module.scss';
import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AbilityTo, AbilityState, CustomerType } from 'behavior/user/constants';
import { NavigationGroupCode, ComponentGroup } from 'behavior/navigation';
import { useAbilities } from 'components/objects/user';
import { Link } from 'components/primitives/links';
import SidebarLink from './SidebarLink';
import { routesBuilder } from 'routes';
import { useLoadEffect, useIsOffline } from 'utils/hooks';
import { renderHTML } from 'utils/render';
import { useLoadNavigation, useAriaLabel } from 'components/objects/navigation';
import { requestPendingAuthorizationsCount } from 'behavior/pages/orderAuthorizations';

const ProfileSidebar = ({ linkKey, styles: propStyles }) => {
  const abilities = useSidebarAbilities();
  const [
    canViewReturnOrders,
    showViewMyAccountPage,
    showEditProfile,
    showViewOrders,
    showViewQuotes,
    showViewInvoices,
    showViewReturnReceipts,
    showViewCreditNotes,
    showViewShipments,
    showUseSalesAgreements,
    showOrderTemplates,
    showCreateDocFreeReturnOrder,
    showCreateSubAccount,
    showAuthorizeOrders,
  ] = abilities.map(ability => ability === AbilityState.Available);

  const offlineMode = useIsOffline();
  const customerType = useSelector(({ user: { customerType } }) => customerType);
  const isB2cCustomer = customerType === CustomerType.B2C;

  const dispatch = useDispatch();

  const [items, ready] = useLoadNavigation(ComponentGroup.Default, NavigationGroupCode.MyAccount);

  const ariaLabel = useAriaLabel(NavigationGroupCode.MyAccount);

  const pendingAuthorizationsCount = useSelector(({ page: { pendingAuthorizationsCount } }) => pendingAuthorizationsCount);
  useLoadEffect(() => {
    if (showAuthorizeOrders && pendingAuthorizationsCount == null)
      dispatch(requestPendingAuthorizationsCount());
  }, [pendingAuthorizationsCount, showAuthorizeOrders]);

  const isActive = key => linkKey === key;

  return (
    <nav style={propStyles} aria-label={ariaLabel}>
      <ul className={styles.asideLinks}>
        {
          showViewMyAccountPage &&
          (
            <SidebarLink
              isActive={isActive('/')}
              route={routesBuilder.forMyAccount()}
              textKey="AccountDashboard"
              className={`${styles.asideLinkItem} ${styles.profile}`}
            />
          )
        }
        {
          !offlineMode && showEditProfile &&
          (
            <SidebarLink
              isActive={isActive('edit')}
              route={routesBuilder.forEditProfile()}
              textKey={isB2cCustomer ? 'MyAccount_MyProfileDescription' : 'MyAccount_CompanyDetailsIntro'}
              className={`${styles.asideLinkItem} ${styles.edit}`}
            />
          )
        }
        {
          showViewOrders &&
          (
            <SidebarLink
              isActive={isActive('orders')}
              route={routesBuilder.forOrders()}
              textKey="MyOrders"
              className={`${styles.asideLinkItem} ${styles.orders}`}
            />
          )
        }
        {
          showViewQuotes &&
          (
            <SidebarLink
              isActive={isActive('quotes')}
              route={routesBuilder.forQuotes()}
              textKey="MyQuotes"
              className={`${styles.asideLinkItem} ${styles.quotes}`}
            />
          )
        }
        {
          showViewInvoices &&
          (
            <SidebarLink
              isActive={isActive('invoices')}
              route={routesBuilder.forInvoices()}
              textKey="MyInvoices"
              className={`${styles.asideLinkItem} ${styles.invoices}`}
            />
          )
        }
        {
          canViewReturnOrders &&
          (
            <SidebarLink
              isActive={isActive('returnOrders')}
              route={routesBuilder.forReturnOrders()}
              textKey="MyReturnOrders"
              className={`${styles.asideLinkItem} ${styles.rma}`}
            />
          )
        }
        {
          showViewReturnReceipts &&
          (
            <SidebarLink
              isActive={isActive('returnReceipts')}
              route={routesBuilder.forReturnReceipts()}
              textKey="MyReturnReceipts"
              className={`${styles.asideLinkItem} ${styles.returnReceipts}`}
            />
          )
        }
        {
          showViewCreditNotes &&
          (
            <SidebarLink
              isActive={isActive('creditNotes')}
              route={routesBuilder.forCreditNotes()}
              textKey="MyCreditNotes"
              className={`${styles.asideLinkItem} ${styles.creditNotes}`}
            />
          )
        }
        {
          showViewShipments &&
          (
            <SidebarLink
              isActive={isActive('shipments')}
              route={routesBuilder.forShipments()}
              textKey="MyShipments"
              className={`${styles.asideLinkItem} ${styles.shipments}`}
            />
          )
        }
        {
          showUseSalesAgreements &&
          (
            <SidebarLink
              isActive={isActive('agreements')}
              route={routesBuilder.forSalesAgreements()}
              textKey="MySalesAgreements"
              className={`${styles.asideLinkItem} ${styles.salesAgreements}`}
            />
          )
        }
        {
          showOrderTemplates &&
          (
            <SidebarLink
              isActive={isActive('templates')}
              route={routesBuilder.forOrderTemplates()}
              textKey="MyOrderTemplates"
              className={`${styles.asideLinkItem} ${styles.templates}`}
            />
          )
        }
        {
          !canViewReturnOrders && showCreateDocFreeReturnOrder &&
          (
            <SidebarLink
              isActive={isActive('returnOrders')}
              route={routesBuilder.forCreateDocFreeReturnOrder()}
              textKey="ButtonText_CreateNewReturnOrder"
              className={`${styles.asideLinkItem} ${styles.returnOrder}`}
            />
          )
        }
        {
          showCreateSubAccount &&
          (
            <SidebarLink
              isActive={isActive('subAccounts')}
              route={routesBuilder.forSubAccounts()}
              textKey="ManageSubAccounts"
              className={`${styles.asideLinkItem} ${styles.subAccounts}`}
            />
          )
        }
        {
          showAuthorizeOrders &&
          (
            <SidebarLink
              isActive={isActive('authorizations')}
              route={routesBuilder.forOrderAuthorizations()}
              textKey="Authorizations"
              className={`${styles.asideLinkItem} ${styles.authorizations}`}
              count={pendingAuthorizationsCount}
              isLoading={pendingAuthorizationsCount == null}
            />
          )
        }
        {
          ready && items.map(({ link, title, cssClass }, index) => {
            return (
              <li key={index} className={`${styles.asideLinkItem} ${cssClass || ''}`}>
                <Link {...link}>{title && renderHTML(title)}</Link>
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
};

ProfileSidebar.propTypes = {
  linkKey: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

export default memo(ProfileSidebar);

const abilityKeys = [
  AbilityTo.ViewReturnOrders,
  AbilityTo.ViewMyAccountPage,
  AbilityTo.EditProfile,
  AbilityTo.ViewOrders,
  AbilityTo.ViewQuotes,
  AbilityTo.ViewInvoices,
  AbilityTo.ViewReturnReceipts,
  AbilityTo.ViewCreditNotes,
  AbilityTo.ViewShipments,
  AbilityTo.UseSalesAgreements,
  AbilityTo.UseOrderTemplates,
  AbilityTo.CreateDocFreeReturnOrder,
  AbilityTo.CreateSubAccount,
  AbilityTo.AuthorizeOrders,
];

function useSidebarAbilities() {
  return useAbilities(abilityKeys).abilities;
}
